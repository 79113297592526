/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { toGreek, toEnglish } from '../../features/languageDecider'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from '@material-ui/core/Divider';
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const language = useSelector((state) => state.language.value)
  const dispatch = useDispatch()

  const classes = useStyles();
  return (
    <div></div>
    // <List className={classes.list}>
    //   <ListItem className={classes.listItem}>
    //     <Button
    //       href="/ergo"
    //       color="transparent"
    //       // target="_blank"
    //       className={classes.navLink}
    //     >
    //       {language ? "ΤΟ ΕΡΓΟ" : "THE PROJECT"}
    //     </Button>
    //   </ListItem>
    //   <ListItem className={classes.listItem}>
    //     <CustomDropdown
    //       noLiPadding
    //       buttonText={language ? "ΕΡΕΥΝΑ" : "RESEARCH"}
    //       buttonProps={{
    //         className: classes.navLink,
    //         color: "transparent",
    //       }}
    //       // buttonIcon={Apps}
    //       dropdownList={[
    //         <Link to="/paricipate" className={classes.dropdownLink}>
    //           {language ? "ΛΑΒΕ ΜΕΡΟΣ" : "PARTICIPATE IN RESEARCH"}
    //         </Link>,
    //         <Link to="/methodology" className={classes.dropdownLink}>
    //           {language ? "ΜΕΘΟΔΟΛΟΓΙΑ" : "METHODOLOGY"}
    //         </Link>,
    //         <Link to="/research-page" className={classes.dropdownLink}>
    //           {language
    //             ? "ΑΠΟΤΕΛΕΣΜΑΤΑ ΕΡΕΥΝΑΣ"
    //             : "RESEARCH RESULTS"}
    //         </Link>,
    //         <Link to="/reportspublications" className={classes.dropdownLink}>
    //           {language ? "ΕΚΘΕΣΕΙΣ / ΔΗΜΟΣΙΕΥΣΕΙΣ" : "REPORTS/PUBLICATIONS"}
    //         </Link>,
    //       ]}
    //     />
    //   </ListItem>
    //   <ListItem className={classes.listItem}>
    //     <Button
    //       href="/team"
    //       color="transparent"
    //       // target="_blank"
    //       className={classes.navLink}
    //     >
    //       {language ? "ΟΜΑΔΑ" : "TEAM"}
    //     </Button>
    //   </ListItem>
    //   <ListItem className={classes.listItem}>
    //     <Button
    //       href="/contactus"
    //       color="transparent"
    //       // target="_blank"
    //       className={classes.navLink}
    //     >
    //       {language ? "ΕΠΙΚΟΙΝΩΝΙΑ" : "CONTACT US"}
    //     </Button>
    //   </ListItem>
      /* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/YouthTrams"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem> */
    //   <ListItem className={classes.listItem}>
    //     <Button
    //       color="transparent"
    //       target="_blank"
    //       className={classes.navLink}
    //       onClick={() => {
    //         language ? dispatch(toEnglish()) : dispatch(toGreek());
    //       }}
    //     >
    //       {language ? "ΕΛ" : "EN"}
    //     </Button>
    //   </ListItem>
    // </List>
  );
}
