import { createSlice } from '@reduxjs/toolkit'

export const languageDecider = createSlice({
  name: 'language',
  initialState: {
    value: localStorage.getItem('lang') == undefined ? true : JSON.parse(localStorage.getItem('lang'))
  },
  reducers: {
    toGreek: (state) => {
      state.value = true
      localStorage.setItem('lang', true);
    },
    toEnglish: (state) => {
      state.value = false
      localStorage.setItem('lang', false);
    },
  },
})

// Action creators are generated for each case reducer function
export const { toGreek, toEnglish } = languageDecider.actions

export default languageDecider.reducer