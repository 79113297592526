import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import store from './store'
import { Provider } from 'react-redux'

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
// import ErgoPage from "views/Ergo/Components.js";
// import TeamPage from "views/LandingPage/LandingPage.js";
// import ContactUsPage from "views/Contact/LandingPage.js";
// import ParticipatePage from "views/LoginPage/LoginPage.js";
// import ResearchPage from "views/ResearchPage/ResearchPage.js";
// import MethodologyPage from "views/MethodologyPage/Methodology.js";
// import ReportsPage from "views/ReportsPage/Methodology.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        {/* <Route path="/team" component={TeamPage} />
        <Route path="/contactus" component={ContactUsPage} />
        <Route path="/reportspublications" component={ReportsPage} />
        <Route path="/ergo" component={ErgoPage} />
        <Route path="/paricipate" component={ParticipatePage} />
        <Route path="/research-page" component={ResearchPage} />
        <Route path="/methodology" component={MethodologyPage} /> */}
        <Route path="/" component={Components} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
