import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
// import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";

import { ReactComponent as Logo_tmp } from "../../assets/logo_tmp.svg";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  // const icons = Object.assign({}, {
  //   Logo_tmp
  // })
  return (
    <div>
      {/* <Header
        brand={<Logo_tmp onClick={() => {window.location.href = '/'}}/>}
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        {...rest}
      /> */}
      {/* <div
        style={{
          height: 200,
        }}
      ></div> */}
      <div
        // className={classNames(
        //   classes.main,
        //   classes.mainRaised,
        //   classes.defaultFont
        // )}
      >
        <SectionBasics />
      </div>
      {/* <Footer /> */}
    </div>
  );
}
