import React, { useState } from "react";
// import { Document, Page } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// import theFile from "assets/somefile.pdf";
import theFile from "assets/obsfile.pdf";
// import theFile2 from "assets/base64obs.txt";
// import PDFViewer from "pdf-viewer-reactjs";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
} from "@coreui/react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import originalSmall from "assets/orginalPrintaformSmall.png";
import originalSmall from "assets/obslogo.svg";
import { IconButton } from "@mui/material";




// const useStyles = makeStyles(container);
// const classes = useStyles();

function MyApp() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(10);
  // const [theFile, setFile] = useState('http://www.africau.edu/images/default/sample.pdf');
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };
  return (
    <GridContainer justify="center" 
    // height="100vh"
    style={{
      display: 'flex',
      // height: "100vh"
    }}>
      {/* align="center" */}
      <GridItem xs={12} sm={12} md={6}   style={{ 
        //  "flex": 1,
    // "display": "flex",
    
    "justifyContent": "center",
    // "alignItems": "center",
    "background": "#f0f0f0",}}>
        <GridContainer  className="containerText" >
        {/* justify="center" */}
          <GridItem 
            xs={11}
            sm={11}
            md={11}
            lg={12}
            xl={12}
            align="center"
            style={{ color: "black", "overflowX":"hidden",  display: "flex", "alignItems": "center"  ,"justifyContent": "center"        }}
          >
            {/* <br></br> */}
            <div >
              <div style={{"display": "flex", "paddingBottom":"2%"}}>
            <img
              src={originalSmall}
              alignself="left"
              style={{ alignself: "left" ,  height: "70px",
               }}
    
            />
            </div>
            {/*style={{ width: "100%" }} */}
            {/* <div
              style={{
                height: 50,
              }}
            ></div> */}
            
            <p
              style={{
                textAlign: "left",
                "fontFamily": "Arial Black",
                // "fontWeight": "bold",
                color: "default",
                "fontSize": "17pt",
                // paddingLeft:"17%"
                // "line-height": "1.3em"

              }}
            >
              
              While our website is under<br></br>maintenance take a break<br></br>and have a look at our<br></br>presentation.

            </p>
            
            <p
              style={{
                textAlign: "left",
                "fontFamily": "Arial",
                // "font-weight": "light",
                color: "#8d8d8d",
                "fontSize": "14pt",
                // paddingLeft:"17%"
                
              }}
            >


              For further information contact us.<br></br>
              <IconButton style={{ color: "#2b84ae" }} onClick={()=> window.open("https://www.linkedin.com/company/the-obsidian-co", "_blank")}><LinkedInIcon /></IconButton> 
              <IconButton  style={{ color: "#2b84ae" }} onClick={()=> window.open("https://www.instagram.com/theobsidianco/?hl=en", "_blank")}><InstagramIcon /></IconButton>
              <IconButton style={{ color: "#2b84ae" }} onClick={()=> window.open("https://www.facebook.com/TheObsidianCo/", "_blank")}><FacebookIcon /></IconButton>
            @theObsidianCo
             <br></br>
             <IconButton style={{ color: "#2b84ae" }} disabled ><MailOutlineIcon /></IconButton>

              info@theobsidianco.com
             <br></br>
             <IconButton style={{ color: "#2b84ae" }} disabled ><PhoneIphoneIcon /></IconButton>

              +357-24400124


              
            </p>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} style={{"background": "#2b84ae"}}
      className="thecontainer"
      align="center">
        <Document
          file={theFile}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={" "}
          options={options}
          fillWidth
        >
          <Page loading={" "} size="100%" scale={0.5} pageNumber={1} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={2} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={3} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={4} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={5} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={6} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={7} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={8} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={9} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={10} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={11} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={12} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={13} />
          <Page loading={" "} size="100%" scale={0.5} pageNumber={14} />
        </Document>
      </GridItem>
    </GridContainer>
  );
}
export default MyApp;
